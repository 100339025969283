@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Source+Sans+Pro:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap');

$primary: #3697bb;
$secondary: #bb5a36;
$light: #eee;
$dark: #111;

$font-source: 'Source Sans Pro', sans-serif;
$font-josefin: 'Josefin Sans', sans-serif;

@mixin breakpoint($point) {
    @if $point == 700 {
        @media (min-width: 700px) {
            @content;
        }
    }
}