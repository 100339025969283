@import "./variables.scss";

.home {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        background-color: $primary;
        //padding-right: 20%;

        &__title {
            margin: 10px;
            margin: 90px 15px 80px 15px;
            text-align: center;
            font-weight: 700;
            font-size: 50px;
            font-family: $font-josefin;
        }

        &__text {
            max-width: 1100px;
            margin: 0 15px 60px 15px;
            text-align: justify;
            font-size: 20px;
        }

        &__wave {
            background-color: aliceblue;
            //top: 45vh;
            left: 0;
            width: 100%;
            overflow: hidden;
            line-height: 0;
            z-index: 0;

            &__svg {
                position: relative;
                display: block;
                width: calc(100% + 1.3px);
                height: 125px;

                &__path {
                    fill: $primary;
                }
            }
        }
    }

    &__logo {
        display: none;

        @include breakpoint(700) {
            display: block;
            position: absolute;
            transform: translateY(-60%);
            right: 3vw;
            width: 20%;
            min-width: 100px;
        }
    }

    &__bottom {
        display: flex;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10vh;

        @include breakpoint(700) {
            display: flex;
            justify-content: center;
            margin-left: calc(20% + 3vw);
            margin-right: calc(20% + 3vw);
        }

        &__container {
            // width: 100%;
            //margin-left: 20vw;
            margin-top: 8vh;

            text-align: center;

            &__button {
                //width: 110px;
                display: inline-block;
                line-height: 1;
                margin: 0.5em;
                padding: 1.5em 3em;
                border: 2px solid;

                text-decoration: none;
                font-size: 20px;
                font-weight: 600;
                color: #bb5a36;
                background: none;
                transition: 0.5s;

                &:hover {
                    box-shadow: inset 0 0 0 3em $primary;
                    border-color: $primary;
                    color: $dark;
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }
    }
}
