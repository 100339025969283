@import './variables.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: $secondary;
    width: 100%;
    overflow: hidden;

    &__logo {
        margin-left: 10px;
        width: 80px;
    }

    &__title {

    }

    &__icon {
        cursor: pointer;
        margin-right: 20px;
        font-size: 2rem;

        &:hover {
            font-size: 2.3rem;
        }
    }

}
