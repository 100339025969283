@import './variables.scss';

* {
    margin: 0;
    padding: 0;
}


body {
    color: #111;
    background-color: aliceblue;
    font-family: $font-source;
}
